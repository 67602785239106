.App {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
}

h1 {
  text-align: center;

  font-size: 4rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 0.5rem #570ba9;
  margin-bottom: 2rem;
}

h2 {
  color:rgb(255, 230, 0);
  text-shadow: 0 0 0.5rem #570ba9;
  font-size: 3rem;
  text-align: left !important;
}

.two-columns {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80vw;
}

.column {
  width: 48%;
}

.tableHighscore {
  width: 100%;
  background-color: #9d1ed3a4;
  color: white;
  padding: 1rem;
  font-size: 2rem;
}

.tableHighscore tr:first-child th {
  color: rgb(255, 230, 0);
}

.tableHighscore .column1 {
  width: 2rem;
}

.tableHighscore .column2 {
  text-align: left;
  padding-left: 3rem;
}

.tableHighscore .column3 {
  text-align: right;
}

.tableHighscore tr td, .tableHighscore tr th {
  padding: 0.5rem 0;
  border-bottom: 1px solid #fff;
}

.tableHighscore tr td:last-child {
  text-align: right;
}

.tableHighscore tr:last-child td, .tableHighscore tr:last-child th {
  border-bottom: none;
}

